<template>
    <tr>
        <td class="sticky-col">
            <div style="margin-left: 20px">
                {{ docname }}
            </div>
        </td>
        <template>
            <td>
                <template v-if="data['executed_on_date'] !== undefined && data['executed_on_date']['total']">
                    <span style="cursor: pointer; text-decoration: underline;"
                          @click="seeInstallations('executed_on_date',classification, data['executed_on_date']['installations'])">
                        {{ data['executed_on_date']['total'] }}
                    </span>
                </template>
                <template v-else>
                    0
                </template>
            </td>
            <td> {{ data['percentage_executed_on_date'] }}%</td>
            <td>
                <div v-if="data['executed_out_of_date'] !== undefined && data['executed_out_of_date']['total'] !== ''">
                    <template v-if="data['executed_out_of_date']['total']">
                             <span style="cursor: pointer; text-decoration: underline;"
                                   @click="seeInstallations('executed_out_of_date',classification, data['executed_out_of_date']['installations'])">
                                  {{ data['executed_out_of_date']['total'] }}
                              </span>
                    </template>
                    <template v-else>
                        0
                    </template>
                </div>
            </td>
            <td> {{ data['percentage_executed_out_of_date'] }}%</td>
            <td class="bg-light-grey">
                <template v-if="data['executed_out_of_date'] !== undefined && data['executed_on_date'] !== undefined">
                     <span style="cursor: pointer; text-decoration: underline;"
                           @click="seeInstallations('executed_out_of_date', classification, ({ ...data['executed_on_date']['installations'], ...data['executed_out_of_date']['installations'] }))">
                          {{ data['executed_on_date']['total'] + data['executed_out_of_date']['total'] }}
                     </span>
                </template>
            </td>
            <td class="bg-light-grey"> {{ data['percentage_total_executed'] }}%</td>
        </template>
        <template v-if="showPlanned" id="planificado">
            <td>
                <template v-if="data['planned'] !== undefined && data['planned']['total']">
                    <span style="cursor: pointer; text-decoration: underline;"
                          @click="seeInstallations('planned',classification, data['planned']['installations'])">
                                        {{ data['planned']['total'] }}
                    </span>
                </template>
                <template v-else>
                    0
                </template>
            </td>
            <td> {{ data['percentage_planned'] }}%</td>
            <td>
                <template v-if="data['planned_less_3_months'] !== undefined && data['planned_less_3_months']['total']">
                    <span style="cursor: pointer; text-decoration: underline;"
                          @click="seeInstallations('planned_less_3_months', classification,data['planned_less_3_months']['installations'])">
                                        {{ data['planned_less_3_months']['total'] }}
                    </span>
                </template>
                <template v-else>
                    0
                </template>
            </td>
            <td> {{ data['percentage_planned_less_3_months'] }}%</td>
            <!--            <td>
                            <template v-if="data['planned_validation'] !== undefined && data['planned_validation']['total']">
                                <span style="cursor: pointer; text-decoration: underline;"
                                      @click="seeInstallations('planned_validation',classification, data['planned_validation']['installations'])">
                                                    {{ data['planned_validation']['total'] }}
                                </span>
                            </template>
                            <template v-else>
                                0
                            </template>
                        </td>
                        <td> {{ data['percentage_planned_validation'] }}%</td>-->
            <td class="bg-light-grey">
                <template>
                     <span style="cursor: pointer; text-decoration: underline;"
                           @click="seeInstallations('planned', classification, ({ ...data['planned']['installations'], ...data['planned_less_3_months']['installations'] }))">
                          {{ data['planned']['total'] }}
                     </span>
                </template>

            </td>
            <td class="bg-light-grey">
                {{ data['percentage_total_planned'] }}%
            </td>
        </template>
        <template id="out-of-date">
            <td>
                <div>
                    <template
                        v-if="data['not_executed_less_3_months'] !== undefined && data['not_executed_less_3_months']['total'] !== ''">
                        <span style="cursor: pointer; text-decoration: underline;"
                              @click="seeInstallations('not_executed_less_3_months',classification, data['not_executed_less_3_months']['installations'])">
                                        {{ data['not_executed_less_3_months']['total'] }}
                        </span>
                    </template>
                    <template v-else>
                        0
                    </template>
                </div>
            </td>
            <td>{{ data['percentage_out_of_date_less_3_months'] }}%</td>
            <td>
                <div>
                    <template
                        v-if="data['not_executed_more_3_months'] !== undefined ">
                         <span style="cursor: pointer; text-decoration: underline;"
                               @click="seeInstallations('not_executed_more_3_months', classification,data['not_executed_more_3_months']['installations'])">
                                        {{ data['not_executed_more_3_months']['total'] }}
                        </span>
                    </template>
                    <template v-else>
                        0
                    </template>
                </div>
            </td>
            <td>{{ data['percentage_out_of_date_more_3_months'] }}%</td>
            <td class="bg-light-grey">
                <div>
                    <template v-if="data['not_executed_less_3_months'] !== undefined && data['not_executed_more_3_months'] !== undefined">
                         <span style="cursor: pointer; text-decoration: underline;"
                               @click="seeInstallations('not_executed_less_3_months', classification,({ ...data['not_executed_less_3_months']['installations'], ...data['not_executed_more_3_months']['installations'] }))">
                             {{ data['not_executed_less_3_months']['total'] + data['not_executed_more_3_months']['total'] }}
                          </span>
                    </template>
                    <template v-else>
                        0
                    </template>
                </div>
            </td>
            <td class="bg-light-grey">
                {{ data['percentage_total_not_executed'] }}%
            </td>
            <td>
                <template>
                     <span style="cursor: pointer; text-decoration: underline;"
                           @click="seeInstallations('total_doc', classification,({ ...data['executed_on_date']['installations'],...data['executed_out_of_date']['installations'],...data['not_executed_more_3_months']['installations'],...data['not_executed_less_3_months']['installations'], ...data['planned']['installations'],...data['planned_less_3_months']['installations'],...data['planned_validation']['installations'] }))">
                         {{ data['total_doc'] }}
                      </span>
                </template>
            </td>
            <td>
                100%
            </td>
        </template>
    </tr>
</template>

<script>
import {mapState} from "vuex"
import helpers from "@/resources/helpers/helpers"
import {createHelpers} from "vuex-map-fields"

const {mapFields} = createHelpers({
    getterType: 'dashboardMetricsVhe/getField',
    mutationType: 'dashboardMetricsVhe/updateField',
})

export default {
    name: "CAEView",
    props: ['docname', 'data', 'classification'],
    data() {
        return {
            showRowsResume: false,
            showRowsInstallations: false,
            expandedRows: {},
            activeGroups: [],

            showLoading: false,
            caraio: [],

            modalShow: {
                executed_on_date: false,
                executed_out_of_date: false,
                not_executed_less_3_months: false,
                not_executed_more_3_months: false,
                planned: false,
                planned_less_2_months: false,
                planned_validation: false,
                total: false,
            },



            helpers
        }
    },
    computed: {
        ...mapState('dashboardMetricsVhe', ['dashboard_metrics']),
        ...mapFields({
            dashboard_metrics: 'dashboard_metrics',
            installation_list: 'installation_list',
            tabIndex: 'tabIndex',
            filter: 'filter',
            contentName: 'contentName',
            installations_total: 'installations_total'
        }),
        showPlanned: function () {
            const currentYear = new Date().getFullYear()

            return this.filter.year === currentYear
        },
    },
    methods: {
        can(permission) {
            return this.$store.getters.can(permission)
        },
        seeInstallations(content, classification, installationsList = null) {
            this.tabIndex++
            this.installation_list = []
            this.contentName = this.setTableTitle(content)

            const installationIdsNew = []

            for (const key in installationsList) {
                if (installationsList.hasOwnProperty(key)) {

                    const installations = installationsList[key]
                    //  console.log(installations)

                    for (const keyInst in installations) {
                        //  console.log(keyInst)
                        if (installations.hasOwnProperty(keyInst)) {
                            console.log(installations[keyInst])
                            if (installations[keyInst].id) {
                                installationIdsNew.push(installations[keyInst].id)
                            }
                        }

                    }

                }
            }

            console.log(installationIdsNew)
            try {
                this.$store.dispatch('masterInstallationVhe/getChildInstallationsByIds', {
                    relations: ['typology', 'installationType', 'level_1', 'level_2', 'level_3', 'segment', 'providerContractEE', 'responsible'],
                    ids: installationIdsNew
                })
                .then(response => {
                    this.showLoading = false

                    if (response) {
                        let responseData = []
                        for (const installationKey in installationsList) {

                            if (installationsList.hasOwnProperty(installationKey)) {
                                const installations = installationsList[installationKey]
                                for (const keyInst in installations) {
                                    //  console.log(keyInst)
                                    if (installations.hasOwnProperty(keyInst)) {
                                        const installation = installations[keyInst]
                                        responseData = response.find(item => item.id === installation.id)
                                        if (responseData) {
                                            const copiedResponse = {...responseData}
                                            copiedResponse.effective_date = installation.effective_date
                                            copiedResponse.expected_date = installation.expected_date
                                            copiedResponse.classification = classification
                                            copiedResponse.document_name = installation.document_name
                                            this.installation_list.push(copiedResponse)
                                        }
                                    }

                                }
                            }
                        }
                    } else {
                        console.error('Erro ao obter os dados das instalações:', response)
                    }
                })
                .catch(error => {
                    this.showLoading = false
                    console.error('Erro ao obter os dados das instalações:', error)
                })
            } catch (error) {
                console.error('Erro ao obter os dados das instalações:', error)
                throw error
            }
        },
        setTableTitle(content)
        {
            if(content == 'executed_on_date'){
                return 'Listado de instalaciones - Ejecutadas en plazo'
            }
            if(content == 'executed_out_of_date'){
                return 'Listado de instalaciones - Ejecutadas fuera de plazo'
            }
            if(content == 'planned'){
                return 'Listado de instalaciones - Planificada'
            }
            if(content == 'planned_less_2_months'){
                return 'Listado de instalaciones - Planificada - menos de dos meses'
            }
            if(content == 'planned_validation'){
                return 'Listado de instalaciones - Planificada - pendiente de validación'
            }
            if(content == 'not_executed_less_3_months'){
                return 'Listado de instalaciones - Fuera de plazo - menos de 3 meses'
            }
            if(content == 'not_executed_more_3_months'){
                return 'Listado de instalaciones - Fuera de plazo - mas de 3 meses'
            }
            if(content == 'total_doc'){
                return 'Total'
            }
        }
    }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select';

</style>
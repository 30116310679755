<template>
    <b-row>
        <b-col cols="12">
            <filter-component/>
            <b-card v-if="dashboard_metrics.length !== 0">
                <b-overlay
                    variant="white"
                    :show="showLoading"
                    spinner-variant="primary"
                    blur="0"
                    opacity=".75"
                    rounded="sm"
                >
                    <b-tabs v-model="tabIndex">
                        <b-tab title="CM">
                            <div class="dashboard-metrics table-responsive">
                                <table class="table table-striped table-bordered table-sm" width="100%" cellspacing="0">
                                    <thead>
                                    <tr>
                                        <th rowspan="2" class="sticky"/>
                                        <th class="bg-light-success" colspan="6">Ejecutado</th>
                                        <th v-if="showPlanned" class="bg-light-info planned" colspan="6">En curso - En
                                            plazo
                                        </th>
                                        <th class="bg-light-danger" colspan="6">En curso - Fuera de plazo</th>

                                        <th colspan="1">Total Num.</th>
                                        <th colspan="1">Total Porc.</th>
                                    </tr>
                                    <tr>
                                        <th class="bg-light-warning" colspan="2">EN PLAZO</th>
                                        <th class="bg-light-danger" colspan="2">Fuera de PLAZO</th>
                                        <th colspan="1">Num. Ejecutado</th>
                                        <th colspan="1">Porc. Ejecutado</th>
                                        <th v-if="showPlanned" class="bg-light-warning planned" colspan="4">EN PLAZO
                                        </th>
                                        <th v-if="showPlanned" class="planned" colspan="1">Num. En curso - En plazo</th>
                                        <th v-if="showPlanned" class="planned" colspan="1">Porc. En curso - En plazo
                                        </th>
                                        <th colspan="4">FUERA PLAZO</th>
                                        <th colspan="1">Num. En curso - Fuera de plazo</th>
                                        <th colspan="1">Porc. En curso - Fuera de plazo</th>
                                        <th colspan="1"></th>
                                        <th colspan="1"></th>
                                    </tr>
                                    <tr>
                                        <th colspan="1" class="sticky"/>
                                        <th colspan="2"></th>
                                        <th colspan="2"></th>
                                        <th colspan="2"></th>
                                        <th v-if="showPlanned" colspan="2" class="planned">Planificada</th>
                                        <th v-if="showPlanned" colspan="2" class="planned">Prevision 3 meses</th>
                                        <!--                                        <th v-if="showPlanned" colspan="2" class="planned">Pendiente de validaciÃ³n</th>-->
                                        <th v-if="showPlanned" colspan="2" class="planned"></th>
                                        <th colspan="2">Entre 0 y 3 meses</th>
                                        <th colspan="2">Más de 3 meses</th>
                                        <th colspan="2"></th>
                                        <th colspan="1"></th>
                                        <th colspan="1"></th>
                                    </tr>
                                    <tr>
                                        <th colspan="1" class="sticky"/>
                                        <th colspan="1">Num</th>
                                        <th colspan="1">Porc</th>
                                        <th colspan="1">Num</th>
                                        <th colspan="1">Porc</th>
                                        <th colspan="1"></th>
                                        <th colspan="1"></th>
                                        <th v-if="showPlanned" colspan="1" class="planned">Num.</th>
                                        <th v-if="showPlanned" colspan="1" class="planned">Porc</th>
                                        <th v-if="showPlanned" colspan="1" class="planned">Num.</th>
                                        <th v-if="showPlanned" colspan="1" class="planned">Porc</th>
                                        <!--                                        <th v-if="showPlanned" colspan="1" class="planned">Num.</th>
                                                                                <th v-if="showPlanned" colspan="1" class="planned">Porc</th>-->
                                        <th v-if="showPlanned" colspan="1" class="planned"></th>
                                        <th v-if="showPlanned" colspan="1" class="planned"></th>
                                        <th colspan="1">Num.</th>
                                        <th colspan="1">Porc.</th>
                                        <th colspan="1">Num.</th>
                                        <th colspan="1">Porc.</th>
                                        <th colspan="1"></th>
                                        <th colspan="1"></th>
                                        <th colspan="1"></th>
                                        <th colspan="1"></th>
                                    </tr>
                                    </thead>
                                    <tbody v-if="dashboard_metrics.length !== 0">
                                    <template v-for="(statistics, classification) in dashboard_metrics">
                                        <tr :key="Math.random()">
                                            <td class="sticky-col" @click="toggleRowsResume(classification)">
                                                <feather-icon
                                                    :id="`record-row-plus-circle-icon`"
                                                    :icon="expandedClassifications[classification] ? 'MinusSquareIcon' : 'PlusSquareIcon'"
                                                    size="16"
                                                    class="cursor-pointer"
                                                />&nbsp;
                                                <b>{{ classification }}</b>
                                            </td>
                                            <td @click="toggleRowsResume(classification)">
                                                <b>
                                                      <span style="cursor: pointer; text-decoration: underline;"
                                                            @click="seeInstallations('executed_on_date', classification, dashboard_metrics[classification]['executed_on_date']['installations'])">
                                                        {{
                                                              dashboard_metrics[classification]['total_executed_doc_on_date']
                                                          }}
                                                        </span>
                                                </b>
                                            </td>
                                            <td>
                                                {{
                                                    dashboard_metrics[classification]['percentage_executed_class_on_date']
                                                }}%
                                            </td>
                                            <td>
                                                <b>
                                                    <span style="cursor: pointer; text-decoration: underline;"
                                                          @click="seeInstallations('executed_out_of_date', classification, dashboard_metrics[classification]['executed_out_of_date']['installations'])">
                                                        {{
                                                            dashboard_metrics[classification]['total_executed_doc_out_of_date']
                                                        }}
                                                    </span>
                                                </b>

                                            </td>
                                            <td>
                                                {{
                                                    dashboard_metrics[classification]['percentage_executed_class_out_of_date']
                                                }}%
                                            </td>
                                            <td class="bg-light-grey">
                                                <b>
                                                    <span style="cursor: pointer; text-decoration: underline;"
                                                          @click="seeInstallations('', classification, dashboard_metrics[classification]['executed_on_date']['installations'].concat(dashboard_metrics[classification]['executed_out_of_date']['installations']))">
                                                        {{
                                                            dashboard_metrics[classification]['total_executed_class']
                                                        }}
                                                    </span>
                                                </b>
                                            </td>
                                            <td class="bg-light-grey">
                                                {{ dashboard_metrics[classification]['percentage_executed_class'] }}%
                                            </td>
                                            <td v-if="showPlanned" class="planned">
                                                <b>
                                                <span style="cursor: pointer; text-decoration: underline;"
                                                      @click="seeInstallations('', classification, dashboard_metrics[classification]['planned']['installations'])">
                                                        {{
                                                        dashboard_metrics[classification]['total_doc_planned']
                                                    }}
                                                    </span>
                                                </b>
                                            </td>
                                            <td v-if="showPlanned" class="planned">
                                                {{ dashboard_metrics[classification]['percentage_planned_class'] }}%
                                            </td>
                                            <td v-if="showPlanned" class="planned">
                                                <b>
                                                      <span style="cursor: pointer; text-decoration: underline;"
                                                            @click="seeInstallations('', classification, dashboard_metrics[classification]['planned_less_3_months']['installations'])">
                                                        {{
                                                              dashboard_metrics[classification]['total_doc_planned_3_months']
                                                          }}
                                                    </span>
                                                </b>

                                            </td>
                                            <td v-if="showPlanned" class="planned">
                                                {{
                                                    dashboard_metrics[classification]['percentage_planned_3_months_class']
                                                }}%
                                            </td>
                                            <!--                                            <td v-if="showPlanned" class="planned">
                                                                                            <b>
                                                                                            <span style="cursor: pointer; text-decoration: underline;"
                                                                                                  @click="seeInstallations('', classification, dashboard_metrics[classification]['planned_validation']['installations'])">
                                                                                                    {{
                                                                                                    dashboard_metrics[classification]['total_doc_planned_validation']
                                                                                                }}
                                                                                                </span>
                                                                                            </b>
                                                                                        </td>
                                                                                        <td v-if="showPlanned" class="planned">
                                                                                            {{
                                                                                                dashboard_metrics[classification]['percentage_planned_validation_class']
                                                                                            }}%
                                                                                        </td>-->
                                            <td v-if="showPlanned" class="planned bg-light-grey">
                                                <b>
                                                    <span style="cursor: pointer; text-decoration: underline;"
                                                          @click="seeInstallations('', classification, dashboard_metrics[classification]['planned']['installations'].concat(dashboard_metrics[classification]['planned_less_3_months']['installations']))">
                                                        {{
                                                            dashboard_metrics[classification]['total_planned_class']
                                                        }}
                                                </span>
                                                </b>

                                            </td>
                                            <td v-if="showPlanned" class="planned bg-light-grey">
                                                {{
                                                    dashboard_metrics[classification]['percentage_total_planned_class']
                                                }}%
                                            </td>
                                            <td >
                                                <b>
                                                    <span style="cursor: pointer; text-decoration: underline;"
                                                          @click="seeInstallations('', classification, dashboard_metrics[classification]['not_executed_less_3_months']['installations'])">
                                                        {{
                                                            dashboard_metrics[classification]['total_doc_out_of_date_less_3_months']
                                                        }}
                                                    </span>
                                                </b>
                                            </td>
                                            <td>
                                                {{
                                                    dashboard_metrics[classification]['percentage_out_of_date_class_less_3_months']
                                                }}%
                                            </td>
                                            <td>
                                                <b>
                                                    <span style="cursor: pointer; text-decoration: underline;"
                                                          @click="seeInstallations('', classification, dashboard_metrics[classification]['not_executed_more_3_months']['installations'])">
                                                        {{
                                                            dashboard_metrics[classification]['total_doc_out_of_date_more_3_months']
                                                        }}
                                                    </span>
                                                </b>
                                            </td>
                                            <td>
                                                {{
                                                    dashboard_metrics[classification]['percentage_out_of_date_class_more_3_months']
                                                }}%
                                            </td>
                                            <td class="bg-light-grey">
                                                <b>
                                                    <span style="cursor: pointer; text-decoration: underline;"
                                                          @click="seeInstallations('', classification, (dashboard_metrics[classification]['not_executed_less_3_months']['installations'].concat(dashboard_metrics[classification]['not_executed_more_3_months']['installations'])))">
                                                        {{
                                                            dashboard_metrics[classification]['total_out_of_date_class']
                                                        }}
                                                </span>
                                                </b>
                                            </td>
                                            <td class="bg-light-grey">
                                                {{
                                                    dashboard_metrics[classification]['percentage_out_of_date_class']
                                                }}%
                                            </td>
                                            <td>
                                                <b>
                                                    <span style="cursor: pointer; text-decoration: underline;"
                                                          @click="seeInstallations('', classification, (dashboard_metrics[classification]['executed_on_date']['installations'].concat(dashboard_metrics[classification]['executed_out_of_date']['installations']).concat(dashboard_metrics[classification]['not_executed_more_3_months']['installations']).concat(dashboard_metrics[classification]['not_executed_less_3_months']['installations']).concat(dashboard_metrics[classification]['planned']['installations']).concat(dashboard_metrics[classification]['planned_less_3_months']['installations'])))">
                                                        {{
                                                            dashboard_metrics[classification]['total_class_row']
                                                        }}
                                                </span>
                                                </b>
                                            </td>
                                            <td>
                                                <b>
                                                    100%
                                                </b>
                                            </td>

                                        </tr>

                                        <detail-row v-for="(data, docName) in statistics"
                                                    v-if="expandedClassifications[classification] && !arrayOfDocNames.includes(docName)" :data="data"
                                                    :classification="classification"
                                                    :docname="docName" :key="Math.random()"/>

                                    </template>
                                    <tr :key="Math.random()">
                                        <td><b>TOTAL GENERAL</b></td>
                                        <td>
                                            <b>
                                                    <span style="cursor: pointer; text-decoration: underline;"
                                                          @click="seeInstallations('', 'Ejecutados', totals.total_general_executed_on_date.installations)">
                                                     {{totals.total_general_executed_on_date.total}}
                                                </span>
                                            </b>
                                        </td>
                                        <td>
                                            {{totals.percentage_general_executed_on_date}}%</td>
                                        <td>
                                            <b>
                                                    <span style="cursor: pointer; text-decoration: underline;"
                                                          @click="seeInstallations('', 'Ejecutados - Fuera de plazo', totals.total_general_executed_out_of_date.installations)">
                                                     {{totals.total_general_executed_out_of_date.total}}
                                                </span>
                                            </b>
                                        </td>
                                        <td>
                                            {{totals.percentage_general_executed_out_of_date}}%
                                        </td>
                                        <td>
                                            <b>
                                                <span style="cursor: pointer; text-decoration: underline;"
                                                      @click="seeInstallations('', 'En curso - Ejecutados Total', totals.total_general_executed.installations)">
                                                     {{ totals.total_general_executed.total }}
                                                </span>
                                            </b>
                                        <td>{{ totals.percentage_executed_total }}%</td>
                                        <td v-if="showPlanned">
                                            <b>
                                                <span style="cursor: pointer; text-decoration: underline;"
                                                      @click="seeInstallations('', 'En Curso - En Plazo - Planificada', totals.total_general_planned_planned.installations)">
                                                     {{ totals.total_general_planned_planned.total }}
                                                </span>
                                            </b>
                                        </td>
                                        <td v-if="showPlanned"> {{ totals.percentage_general_planned }}%</td>
                                        <td v-if="showPlanned">
                                            <b>
                                                <span style="cursor: pointer; text-decoration: underline;"
                                                      @click="seeInstallations('', 'En Curso - En Plazo - Planificada Menos de 2 meses', totals.total_general_planned_less_3_months.installations)">
                                                     {{ totals.total_general_planned_less_3_months.total }}
                                                </span>
                                            </b>
                                        </td>
                                        <td v-if="showPlanned">{{ totals.percentage_general_planned_less_3_months }}%</td>
                                        <!--                                        <td v-if="showPlanned">
                                                                                    <b>
                                                                                        <span style="cursor: pointer; text-decoration: underline;"
                                                                                              @click="seeInstallations('', 'En Curso - En Plazo - Planificada Pendiente de validaciÃ³n', totals.total_general_planned_validation.installations)">
                                                                                             {{ totals.total_general_planned_validation.total }}
                                                                                        </span>
                                                                                    </b>
                                                                                </td>
                                                                                <td v-if="showPlanned" >{{ totals.percentage_general_planned_validation }}%</td>-->
                                        <td v-if="showPlanned">
                                            <b>
                                                <span style="cursor: pointer; text-decoration: underline;"
                                                      @click="seeInstallations('', 'Planificada - Total', totals.total_general_planned.installations)">
                                                     {{totals.total_general_planned.total}}
                                                </span>
                                            </b>
                                        </td>
                                        <td v-if="showPlanned">{{ totals.percentage_planned_total }}%</td>
                                        <td>
                                            <b>
                                                <span style="cursor: pointer; text-decoration: underline;"
                                                      @click="seeInstallations('', 'En curso - Fuera de plazo - 1 y 3 meses', totals.total_general_not_executed_less_3_months.installations)">
                                                     {{totals.total_general_not_executed_less_3_months.total}}
                                                </span>
                                            </b>
                                        </td>
                                        <td>{{ totals.percentage_general_out_of_date_less_3_months }}%</td>
                                        <td>
                                            <b>
                                                <span style="cursor: pointer; text-decoration: underline;"
                                                      @click="seeInstallations('', 'En curso - Fuera de plazo - mÃ¡s de 3 meses', totals.total_general_not_executed_more_3_months.installations)">
                                                     {{totals.total_general_not_executed_more_3_months.total}}
                                                </span>
                                            </b>
                                        </td>
                                        <td>{{ totals.percentage_general_out_of_date_more_3_months }}%</td>
                                        <td>
                                            <b>
                                                <span style="cursor: pointer; text-decoration: underline;"
                                                      @click="seeInstallations('', 'En curso - Fuera de plazo - Total', totals.total_general_out_of_date.installations)">
                                                     {{totals.total_general_out_of_date.total}}
                                                </span>
                                            </b>
                                        </td>
                                        <td>{{ totals.percentage_out_of_date_total }}%</td>
                                        <td>
                                            <b>
                                                <span style="cursor: pointer; text-decoration: underline;"
                                                      @click="seeInstallations('', 'Total', totals.total_general.installations)">
                                                     {{totals.total_general.total}}
                                                </span>
                                            </b>
                                        </td>
                                        <td><b>100%</b></td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </b-tab>
                        <b-tab :title="contentName">
                            <installations-details-view/>
                        </b-tab>
                    </b-tabs>
                </b-overlay>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
import {BCard, BCol, BOverlay, BRow, BTab, BTabs} from 'bootstrap-vue'
import {mapState} from "vuex"
import helpers from "@/resources/helpers/helpers"
import {createHelpers} from "vuex-map-fields"

import filterComponent from "@/views/vhe/dashboard_metrics/list/components/filterComponent.vue"
import detailRow from "@/views/vhe/dashboard_metrics/list/components/detailRow.vue"
import installationsDetailsView from "@/views/vhe/dashboard_metrics/list/components/installationsDetailsView"

const {mapFields} = createHelpers({
    getterType: 'dashboardMetricsVhe/getField',
    mutationType: 'dashboardMetricsVhe/updateField',
})

export default {
    name: "ListView",
    components: {
        BTabs,
        BTab,
        BRow,
        BCol,
        BCard,
        BOverlay,

        detailRow,
        filterComponent,
        installationsDetailsView
    },
    data() {
        return {
            showRowsResume: false,
            showRowsInstallations: false,
            expandedRows: {},
            activeGroups: [],

            data: [],
            expandedClassifications: {},
            currentYear: null,

            arrayOfDocNames: [
                'executed_on_date', 'executed_out_of_date','not_executed_more_3_months',
                'not_executed_more_3_months','not_executed_less_3_months', 'planned',
                'planned_validation','planned_less_3_months', 'percentage_planned_validation_class',
                'total_executed_doc_on_date', 'total_executed_doc_out_of_date',
                'total_doc_out_of_date_less_3_months', 'total_doc_out_of_date_more_3_months',
                'total_doc_planned_3_months', 'total_doc_planned',
                'total_doc_planned_validation', 'total_executed_class',
                'total_planned_class', 'total_out_of_date_class',
                'total_class_row', 'percentage_out_of_date_class',
                'percentage_executed_class', 'percentage_total_planned_class',
                'percentage_executed_class_out_of_date', 'percentage_executed_class_on_date',
                'percentage_out_of_date_class_less_3_months', 'percentage_out_of_date_class_more_3_months',
                'percentage_planned_class', 'percentage_planned_3_months_class',
                'percentage_planned_validation_class'],

            helpers
        }
    },
    computed: {
        showPlanned: function () {
            const currentYear = new Date().getFullYear()

            return this.filter.year === currentYear
        },
        ...mapState('dashboardMetricsVhe', ['dashboard_metrics']),
        ...mapFields({
            dashboard_metrics: 'dashboard_metrics',
            totals: 'totals',
            installation_list: 'installation_list',
            tabIndex: 'tabIndex',
            filter: 'filter',
            showLoading: 'showLoading',
            contentName: 'contentName'
        }),
    },
    created() {
        // this.installationsByClassification()
    },
    methods: {
        can(permission) {
            return this.$store.getters.can(permission)
        },
        toggleRowsResume(classification) {
            this.$set(this.expandedClassifications, classification, !this.expandedClassifications[classification])
        },
        seeInstallations(content, classification, installationsList = null) {
            this.tabIndex++
            this.installation_list = []
            this.contentName = this.setTableTitle(content)

            // console.log(installationsList)
            const installationIds = Object.keys(installationsList).map(installationKey => {
                return installationsList[installationKey].id
            })

            const installationIdsNew = []

            for (const key in installationsList) {
                if (installationsList.hasOwnProperty(key)) {

                    const installations = installationsList[key]
                    //  console.log(installations)

                    for (const keyInst in installations) {
                        //  console.log(keyInst)
                        if (installations.hasOwnProperty(keyInst)) {
                            console.log(installations[keyInst])
                            if (installations[keyInst].id) {
                                installationIdsNew.push(installations[keyInst].id)
                            }
                        }

                    }

                }
            }
            //    console.log(installationIdsNew)
            this.showLoading = true
            // console.log(installationIds)
            try {
                this.$store.dispatch('masterInstallationVhe/getChildInstallationsByIds', {
                    relations: ['typology', 'installationType', 'responsible',
                        'providerContractEE'],
                    ids: installationIdsNew
                })
                .then(response => {
                    this.showLoading = false

                    if (response) {
                        let responseData = []
                        for (const installationKey in installationsList) {

                            if (installationsList.hasOwnProperty(installationKey)) {

                                const installations = installationsList[installationKey]
                                for (const keyInst in installations) {
                                    //  console.log(keyInst)
                                    if (installations.hasOwnProperty(keyInst)) {
                                        const installation = installations[keyInst]
                                        responseData = response.find(item => item.id === installation.id)
                                        if (responseData) {
                                            const copiedResponse = {...responseData}
                                            copiedResponse.effective_date = installation.effective_date
                                            copiedResponse.expected_date = installation.expected_date
                                            copiedResponse.classification = classification
                                            copiedResponse.document_name = installation.document_name
                                            this.installation_list.push(copiedResponse)
                                        }
                                    }

                                }

                            }
                            /*
                               if (installationsList.hasOwnProperty(installationKey)) {
                                   const installation = installationsList[installationKey]
                                   responseData = response.find(item => item.id === installation.id)
                                   if (responseData) {
                                       const copiedResponse = {...responseData}
                                       copiedResponse.effective_date = installation.effective_date
                                       copiedResponse.expected_date = installation.expected_date
                                       copiedResponse.classification = classification
                                       copiedResponse.document_name = installation.document_name
                                       this.installation_list.push(copiedResponse)
                                   }
                               }*/
                        }
                    } else {
                        console.error('Erro ao obter os dados das instalaÃ§Ãµes:', response)
                    }
                })
                .catch(error => {
                    this.showLoading = false
                    console.error('Error al obtener instalaciones:', error)
                })
            } catch (error) {
                console.error('Error al obtener instalaciones', error)
                throw error
            }
        },
        setTableTitle(content) {
            if (content == '') {
                return 'Listado de instalaciones'
            }

            if (content == 'executed_on_date') {
                return 'Listado de instalaciones - Ejecutadas en plazo'
            }
            if (content == 'executed_out_of_date') {
                return 'Listado de instalaciones - Ejecutadas fuera de plazo'
            }
            if (content == 'planned') {
                return 'Listado de instalaciones - Planificada'
            }
            if (content == 'planned_less_3_months') {
                return 'Listado de instalaciones - Planificada - menos de dos meses'
            }
            if (content == 'planned_validation') {
                return 'Listado de instalaciones - Planificada - pendiente de validaciÃ³n'
            }
            if (content == 'not_executed_less_3_months') {
                return 'Listado de instalaciones - Fuera de plazo - menos de 3 meses'
            }
            if (content == 'not_executed_more_3_months') {
                return 'Listado de instalaciones - Fuera de plazo - mas de 3 meses'
            }
        }
    }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

[dir] .dashboard-metrics {
    font-size: 12px;
}

.bg-light-grey {
    background-color: #f5f5f5;
    color: black;
}

.wrapper {
    position: relative;
    overflow: auto;
    border: 1px solid black;
    white-space: nowrap;
}

.table .sticky, .table td:first-child {
    position: sticky;
    border-top: 1px solid #ebe9f1;
    left: 0;
    background-color: #fafafc;
}

</style>